import { config } from '@hp/core/shared';

import { getKey } from './hooks/useLocalStorage';
import { safeLocalStorage } from './storage';

export const devStorageKey = 'dev';

export type DevStorageData = {
  features: Partial<typeof config.features>;
};

export const hasFeature = (featureName: keyof typeof config.features) => {
  const res =
    typeof window === 'undefined'
      ? null
      : safeLocalStorage.getItem(getKey(devStorageKey));
  if (typeof window === 'undefined')
    console.warn(
      'using hasFeature on the server can returns unexpected results',
    );
  if (res) {
    const data = JSON.parse(res) as DevStorageData;
    return data.features[featureName] ?? config.features[featureName];
  }
  return config.features[featureName];
};

export const getAllFeatures = () => {
  const res =
    typeof window === 'undefined'
      ? null
      : safeLocalStorage.getItem(getKey(devStorageKey));
  if (typeof window === 'undefined')
    console.warn(
      'using getAllFeatures on the server can returns unexpected results',
    );
  if (res) {
    const data = JSON.parse(res) as DevStorageData;
    return { ...config.features, ...data.features };
  }
  return config.features;
};
