import { useEffect, useState } from 'react';

export const useServerTime = (interval = 1000) => {
  const [serverTime, setServerTime] = useState<number>(null);
  const [tzDelta, setTzDelta] = useState<number>(null);

  useEffect(() => {
    //info: dpdServerTime is computed in _document
    setServerTime(window.dpdServerTime);
    setTzDelta(window.dpdTzOffsetDelta);

    const timer = setInterval(() => {
      setServerTime(window.dpdServerTime);
    }, interval);

    return () => clearInterval(timer);
  }, [interval]);

  const correctedTime = serverTime ? serverTime + tzDelta * 60000 : null;
  const correctedObj = serverTime ? new Date(correctedTime) : null;

  return { serverTime, correctedTime, correctedObj };
};
