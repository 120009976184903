export const colors = {
  // dpd color scheme (figma)
  red_main: '#DC0032',
  red_dark: '#A90034',
  red_dark_opacity_01: 'rgba(169, 0, 52, 0.1)',
  red_opacity_005: 'rgba(220, 0, 50, 0.05);',
  black: '#414042',
  black_opacity_05: 'rgba(65, 64, 66, 0.5)',
  gray: '#F5F5F5',
  gray_mid: '#808285',
  gray_warm: '#CAC4BE',
  gray_light: '#E6E7E8',
  gray_badge: '#C4C4C4',
  yellow_badge: '#F2C94C',
  green: '#66A821',
  green_banner: '#509E2E',
  green_bg_light: '#F7FAF4',
  input: '#f4f5f5',
  error: '#EB5757',
  note: '#E4E1Df',
  background: '#EBEFF2',

  // general
  white: '#FFFFFF',
  transparent: 'transparent',
  disabled: '#E6E7E8',
};

export const typography = {
  fontSize: {
    h1: '32px',
    h2: '20px',
    bodyMicro: '12px',
    bodySmall: '14px',
    body: '16px',
    bodyLarge: '18px',
    bodyExtraLarge: '20px',
    desktop: {
      h1: '64px',
      h2: '24px',
    },
    input: {
      text: '16px',
      label: '12px',
    },
  },
  fontFamily: {
    default: 'PlutoSansDPD, sans-serif',
  },
  lineHeight: {
    h1: '40px',
    h2: '24px',
    bodyMicro: '18px',
    bodySmall: '18px',
    body: '24px',
    bodyLarge: '26px',
    desktop: {
      h1: '65px',
      h2: '30px',
    },
  },
  fontWeight: {
    thin: '100',
    extraLight: '200',
    light: '300',
    normal: '400',
  },
};

export const breakpoints = {
  unfixSubmitButton: '400px',
  minDesktop: '768px',
  minDesktopNum: 768,
  midMobile: '425px',
  maxMobile: '767px',
  maxMobileNum: 767,
  bigDesktop: '1024px',
  bigDesktopNum: 1024,
  fullWidth: '1920px',
  ultraWidth: '3440px',
};

export const misc = {
  shadow_button: '0px 4px 5px rgba(131, 132, 132, 0.25)',
  shadow_menu: '0px 2px 10px rgba(0, 0, 0, 0.1)',
  shadow_menu_notOnTop:
    '-5px 2px 3px rgba(0, 0, 0, 0.1), 5px 2px 3px rgba(0, 0, 0, 0.1)',
  shadow_note: '5px 35px 25px -15px rgba(0,0,0,0.13)',
  shadow_form: 'rgba(0, 0, 0, 0.13) 0px 10px 30px',
};

export const elements = {
  navigationHeight: '64px',
  navigationHeightMobile: '96px',
  footerMaxHeight: '240px',
  footerMaxHeightMobile: '280px',
  buttonHeight: '56px',
  inputHeight: '48px',
  inputHeightSmall: '40px',
  buttonHeightMenu: '40px',
  buttonHeightSmall: '48px',
  buttonHeightBottom: '64px',
  buttonMaxWidth: '286px',
  buttonChatMinWidth: '220px',
  formMaxWidth: '350px',
  innerElementMaxWidth: '1024px',
  logoWidthNum: 180,
  desktopContainerWidth: '600px',
  desktopContainerWidthNum: 600,
};

export const spacing = {
  zero: '0',
  unit: (x: number) => `${x * 2}px`,
  /** 2px */
  xxs: '2px',
  /** 4px */
  xs: '4px',
  s: '8px',
  /** 16px (default pading) */
  m: '16px',
  m_plus: '24px',
  /** 32px */
  l: '32px',
  /** 64px */
  xl: '64px',
  /** 128px */
  xxl: '128px',
};
