//todo: use this one from GraphQL
export enum Language {
  CS = 'cs',
  EN = 'en',
}
export type Range = [number, number];

type SpecificCfgNode<TKey extends string | number | symbol, TValue> = Partial<
  Record<TKey, TValue>
> & {
  default: TValue;
};

type ExternalLinks = SpecificCfgNode<
  Language,
  Record<
    | 'dpdWeb'
    | 'faq'
    | 'howWrap'
    | 'privacyPolicy'
    | 'termsConditions'
    | 'cookies'
    | 'responsibility'
    | 'restrictedGoods'
    | 'termsForTransport',
    string
  >
>;

/** returns from specific cfg node required subkey TKey or 'default' if key is missing. */
export const getSpecificCfg = <TKey extends string | number | symbol, TValue>(
  node: SpecificCfgNode<TKey, TValue>,
  key: TKey,
) => {
  if (!key) throw new Error('Invalid cfg key');
  return node[key] ?? node.default;
};

export enum EstimateDeliveryMethod {
  COMPUTE = 'compute',
  ESTIMATE = 'estimate',
}

export const config = {
  features: {
    multiPackage: false,
    v3: false,
    sourceCountry: false,
    parcelShops: false,
    parcelShopsMapIntegration: false,
  },
  constraints: {
    maxCircumference: 300,
    maxCircumferenceParcelShop: 175,
    maxLength: 175,
    maxLengthParcelShop: 100,
    maxWeight: 31,
    maxWeightParcelShop: 15,
    maxInsurance: 50000,
    maxOptionalInsurance: 3000000,
    maxTotalPriceWithVat: 10000,
    paymentWaitingTimeoutMs: 45000,
    maxItemCountInOrder: 10,
  },
  defaults: {
    parcelValue: 60000,
    modalAutoCloseDefaultTimeout: 3000,
  },
  externalLinks: {
    [Language.CS]: {
      dpdWeb: 'https://www.dpd.com/cz/cs/',
      faq: 'https://www.dpd.com/cz/cs/podpora/nejcastejsi-dotazy/',
      howWrap:
        'https://www.dpd.com/cz/cs/podpora/podminky-prepravy/jak-spravne-zabalit-zasilky/',
      privacyPolicy: '/documents/Ochrana-osobnich-udaju.pdf',
      termsConditions: '/documents/Vseobecne-obchodni-podminky-01082020.pdf',
      cookies: '/documents/Cookies.pdf',
      responsibility:
        'https://www.dpd.com/cz/cs/o-nas/spolecenska-odpovednost/',
      restrictedGoods:
        'https://www.dpd.com/cz/cs/podpora/podminky-prepravy/zbozi-vyloucene-z-prepravy/',
      termsForTransport:
        'https://www.dpd.com/cz/en/support/terms-of-transport/',
    },
    default: {
      dpdWeb: 'https://www.dpd.com/cz/en/',
      faq: 'https://www.dpd.com/cz/en/support/',
      howWrap:
        'https://www.dpd.com/cz/en/support/terms-of-transport/packing-instructions/',
      privacyPolicy: 'https://www.dpd.com/cz/en/data-protection/',
      termsConditions:
        'https://www.dpd.com/cz/en/general-terms-and-conditions/',
      cookies: 'https://www.dpd.com/cz/en/data-protection/',
      responsibility: 'https://www.dpd.com/cz/en/company/responsibility/',
      restrictedGoods:
        'https://www.dpd.com/cz/en/support/terms-of-transport/exclusions-from-shipment/',
      termsForTransport: 'https://www.dpd.com/cz/cs/podpora/podminky-prepravy/',
    },
  } as ExternalLinks,
};
