import * as next from 'next';
import nookies from 'nookies';

import { AuthStorageKeys, StorageKeys } from './storageKeys';

export type CookieOptions = {
  maxAge?: number;
  expires?: Date;
  httpOnly?: boolean;
  path?: string;
  domain?: string;
  secure?: boolean;
  sameSite?: boolean | 'lax' | 'strict' | 'none';
};

type ContextReqType =
  | Pick<next.NextPageContext, 'req'>
  | { req: next.NextApiRequest }
  | null
  | undefined;

type ContextResType =
  | Pick<next.NextPageContext, 'res'>
  | { res: next.NextApiResponse }
  | null
  | undefined;

const cookieOptions: CookieOptions = {
  maxAge: 10 * 60 * 60, // in seconds
  sameSite: 'lax',
  path: '/',
};

export const cookies = {
  get: (ctx: ContextReqType, key: StorageKeys) => nookies.get(ctx)[key],
  set: (
    ctx: ContextResType,
    key: StorageKeys,
    value: string,
    otherOptions: CookieOptions = {},
  ) =>
    nookies.set(ctx, key, value, {
      ...cookieOptions,
      ...otherOptions,
    }),
  clearAuthCookies: (ctx: ContextResType) => {
    for (const key of AuthStorageKeys) {
      nookies.destroy(ctx, key, { path: cookieOptions.path });
    }
  },
};
