import csDateFns from 'date-fns/locale/cs';
import enDateFns from 'date-fns/locale/en-US';
import getConfig from 'next/config';

import { ConfigType, Environment, Language } from './types';

const fnsLocales = { cs: csDateFns, en: enDateFns };

const {
  publicRuntimeConfig: { environment, internalServerBase, apiEndpoint },
} = getConfig();

export const config: ConfigType = {
  app: {
    defaultLanguage: Language.CS,
    defaultCountry: 'CZ',
    defaultGoogleApiCountry: 'cz',
    environment: environment as Environment,
    googleSiteId: '',
    googleApiKey:
      environment === Environment.PRODUCTION
        ? 'AIzaSyDaOheSB36yLq7jYR3ITn-yYvK1Y7QGctc'
        : environment === Environment.DEV
        ? 'AIzaSyAUHNSmgdVpt-Lqk3JZOXAIW7Rl03wYyh0'
        : 'AIzaSyBrLb65mtwNgQg7jtK7VUV9aIs0XPw_0as', // Environment.LOCAL
    googleTagManagerId: 'GTM-NK88H9K',
    parcelShopSearchApiUrl:
      'https://pickup.dpd.cz/api/get-parcel-shops-by-address',
    parcelShopSearchUrl: 'https://pickup.dpd.cz',
    parcelShopSearchUrlEn: 'https://pickup.dpd.cz/en',
    onlineCodDpd: 'https://www.dpd.com/cz/cs/online-dobirka/',
    onlineCodDpdEn: 'https://www.dpd.com/cz/en/online-cod/',
    dateFnsLocale: fnsLocales.cs,
    accountOrderListSize: 3,
    accountOrderListBatchSize: 6,
    accountAddressListSize: 2,
    accountParcelShopsListSize: 9,
  },
  api: {
    apiEndpoint,
    graphqlLocalEndpoint: '/graphql',
    internalServerBase,
  },
  contactInfo: {
    email: 'test@test.test',
    phone: '+420 777 777 777',
  },
  customerSupport: {
    phone: '225 373 373',
    phonePrefix: '+420 ',
  },
  emergency: {
    phone: '911',
  },
};
