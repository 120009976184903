import getConfig from 'next/config';

import { Environment } from './types';

const {
  publicRuntimeConfig: { environment },
} = getConfig();

export const regexPassword =
  environment === Environment.LOCAL
    ? /^.{6,}$/
    : /^(?=.*[0-9])(?=.*[a-záéúőóüöíěščřžýůďťň])(?=.*[A-ZÁÉÚŐÓÜÖÍĎŇŤŠČŘŽÝŮ])(?=.*[*.!@$%^&(){}\[\]:;<>,.?\/~_+-=|]).{8,}$/;
