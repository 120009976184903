import { Language } from '@hp/config';

export const getCountryCodeByLanguage = (lang: Language) => {
  switch (lang) {
    case Language.CS:
      return 'cz';
    case Language.EN:
      return 'gb';
    default:
      return null;
  }
};

export const getLanguageName = (lang: Language) => {
  switch (lang) {
    case Language.CS:
      return 'česky';
    case Language.EN:
      return 'english';
    default:
      return lang;
  }
};

export const getLanguageCode = (lang: Language) => {
  switch (lang) {
    case Language.CS:
      return 'CZ';
    case Language.EN:
      return 'EN';
    default:
      return lang;
  }
};

export const getLanguageNameWithCode = (lang: Language) => {
  switch (lang) {
    case Language.CS:
      return 'česky (CZ)';
    case Language.EN:
      return 'english (EN)';
    default:
      return lang;
  }
};

export const languageOptions = Object.values(Language).map(
  (lang: Language) => ({
    value: lang,
    label: getLanguageName(lang),
  }),
);
