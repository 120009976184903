import { addDays, startOfWeek } from 'date-fns';

import { formatDate } from './formatDate';

export const weekDays = (dayFormat = 'ddd') => {
  const dayLabels: Array<string> = [];

  let day = startOfWeek(new Date(Date.now()), { weekStartsOn: 1 });
  for (let n = 0; n < 7; n++) {
    dayLabels.push(formatDate(day, dayFormat));
    day = addDays(day, 1);
  }
  return dayLabels;
};
