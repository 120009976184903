export const alphabeticalGroupedArray = (
  array: Array<string>,
): Array<{ firstLetter: string; items: Array<string> }> => {
  array.sort();

  const groups = array.reduce((r, e) => {
    // get first letter of current element
    const firstLetter = e[0];

    // if there is no property in accumulator with this letter create it
    if (!r[firstLetter]) {
      r[firstLetter] = { firstLetter, items: [e] };
      // if there is push current element to children array for that letter
    } else {
      r[firstLetter].items.push(e);
    }

    // return accumulator
    return r;
  }, {});

  // since data at this point is an object, to get array of values we use Object.values method
  return Object.values(groups);
};
