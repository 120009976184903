import { ApolloError } from 'apollo-client';

export enum ErrorCode {
  ALREADY_EXISTS = 'ALREADY_EXISTS',
  UNAUTHORIZED = 'UNAUTHORIZED',
  INVALID_VERIFICATION_TOKEN = 'INVALID_VERIFICATION_TOKEN',
  NOT_FOUND = 'NOT_FOUND',
}

export const graphQLError = (
  error: ApolloError,
): { errorCode: string; traceId: string } => {
  let errorCode = 'UNKNOWN';
  let traceId = 'N/A';

  if (
    typeof error?.graphQLErrors[0] !== 'undefined' &&
    error?.graphQLErrors[0].extensions
  ) {
    const extensions = error?.graphQLErrors[0].extensions;
    errorCode = extensions.ERROR_CODE;
    traceId = extensions.TRACE_ID;
  }

  return { errorCode, traceId };
};
