import { elements } from '@hp/theme';
import scrollInto, { Options } from 'scroll-into-view-if-needed';

export type ScrollToProps = {
  top: number;
  left: number;
  behavior?: ScrollBehavior;
};

export const scrollTo = ({ top, left, behavior = 'auto' }: ScrollToProps) => {
  try {
    window.scrollTo({
      top,
      left,
      behavior,
    });
  } catch (error) {
    if (error instanceof TypeError) {
      window.scroll(top, left);
    } else {
      throw error;
    }
  }
};

export type ScrollToTopProps = {
  behavior?: ScrollBehavior;
};

export const scrollToTop = ({ behavior = 'auto' }: ScrollToTopProps = {}) => {
  scrollTo({
    top: 0,
    left: 0,
    behavior,
  });
};

const defaultScrollIntoViewOptions: Options & { delta?: string | number } = {
  block: 'nearest',
  behavior: 'smooth',
  scrollMode: 'if-needed',
};

export const scrollIntoView = scrollInto;

export const scrollIntoViewIfNeeded = (
  target: HTMLElement,
  options: Options & { delta?: string | number } = {
    delta: `-${elements.navigationHeight} - 3vh`,
  },
) => {
  let realTarget = target;
  let shift: string = null;
  const {
    delta = null,
    block = defaultScrollIntoViewOptions.block,
    behavior = defaultScrollIntoViewOptions.behavior,
    scrollMode = defaultScrollIntoViewOptions.scrollMode,
  } = options || {};

  if (typeof delta === 'string') {
    const top =
      block === 'end'
        ? target.offsetHeight + target.offsetTop
        : target.offsetTop;
    const value = delta
      .replace(/ /g, '')
      .replace(/\-/g, ' - ')
      .replace(/\+/g, ' + ');
    const operator = value.startsWith(' -') ? '' : ' + ';
    shift = `calc(${top}px ${operator} ${value})`;
  } else if (typeof delta === 'number') {
    const top = target.offsetTop;
    shift = `${top + delta}px`;
  }
  if (shift) {
    const div = document.createElement('div');
    const { style } = div;
    style.height = '1px';
    style.width = '1px';
    style.top = top + 'px';
    style.position = 'absolute';
    style.top = shift;
    const parent = target.offsetParent || target.parentNode;
    parent.appendChild(div);
    realTarget = div;
    setTimeout(() => parent.removeChild(div), 3000);
  }

  scrollIntoView(realTarget, { block, behavior, scrollMode });
};
