import { useEffect, useRef } from 'react';

type Cb = () => void;

/** sets timeout. When callback or delay is not specified, hook is skipped */
export const useTimeout = (
  callback: Cb,
  delay: number | null,
  dependencies = [],
) => {
  const savedCallback = useRef<Cb>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const tick = savedCallback.current
      ? () => {
          savedCallback.current();
        }
      : null;
    if (delay && tick) {
      const id = setTimeout(tick, delay);
      return () => clearTimeout(id);
    }
  }, [delay, ...dependencies]);
};
