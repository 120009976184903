import { Language } from '@hp/core/shared';

export enum DayOfTheWeek {
  'monday' = 'monday',
  'tuesday' = 'tuesday',
  'wednesday' = 'wednesday',
  'thursday' = 'thursday',
  'friday' = 'friday',
  'saturday' = 'saturday',
  'sunday' = 'sunday',
}

// TODO: get day names using translations
// inline trans t('common.dayName.monday')`Pondělí`; returns [object Object]
export const getDayName = (
  dayNumber: number | DayOfTheWeek,
  language: Language,
) => {
  switch (dayNumber) {
    case 0:
    case 7:
    case 'sunday':
      return language === Language.CS ? 'Neděle' : 'Sunday';
    case 1:
    case 'monday':
      return language === Language.CS ? 'Pondělí' : 'Monday';
    case 2:
    case 'tuesday':
      return language === Language.CS ? 'Úterý' : 'Tuesday';
    case 3:
    case 'wednesday':
      return language === Language.CS ? 'Středa' : 'Wednesday';
    case 4:
    case 'thursday':
      return language === Language.CS ? 'Čtvrtek' : 'Thursday';
    case 5:
    case 'friday':
      return language === Language.CS ? 'Pátek' : 'Friday';
    case 6:
    case 'saturday':
      return language === Language.CS ? 'Sobota' : 'Saturday';
    default:
      return '';
  }
};
