/** safe way for use storages. Inspired by https://michalzalecki.com/why-using-localStorage-directly-is-a-bad-idea/ */
export const storageFactory = (
  getStorage: () => Storage,
): Storage & { isSupported: () => boolean } => {
  let inMemoryStorage: { [key: string]: string } = {};
  let supported: boolean | null = null;

  const isSupported = () => {
    if (supported !== null) return supported;

    //@ts-ignore
    if (!process.browser) {
      supported = false;
      return false;
    }
    try {
      const testKey = '__testing_key__';
      getStorage().setItem(testKey, testKey);
      getStorage().removeItem(testKey);
      supported = true;
      return true;
    } catch (e) {
      supported = false;
      return false;
    }
  };

  const clear = () => {
    if (isSupported()) {
      getStorage().clear();
    } else {
      inMemoryStorage = {};
    }
  };

  const getItem = (name: string) => {
    //@ts-ignore
    if (!process.browser) {
      console.error(
        new Error(
          `Attempt to use local or session storage on server! Key is ${name} - getItem`,
        ),
      );
      return null;
    }
    if (isSupported()) {
      return getStorage().getItem(name);
    }
    if (inMemoryStorage.hasOwnProperty(name)) {
      return inMemoryStorage[name];
    }
    return null;
  };

  const key = (index: number) => {
    if (isSupported()) {
      return getStorage().key(index);
    } else {
      return Object.keys(inMemoryStorage)[index] || null;
    }
  };

  const removeItem = (name: string) => {
    if (isSupported()) {
      getStorage().removeItem(name);
    } else {
      delete inMemoryStorage[name];
    }
  };

  const setItem = (name: string, value: string) => {
    //@ts-ignore
    if (!process.browser) {
      console.error(
        new Error(
          `Attempt to use local or session storage on server! Key is ${name} - setItem`,
        ),
      );
      return;
    }
    if (isSupported()) {
      if (value === null || value === undefined) {
        getStorage().removeItem(name);
      } else {
        getStorage().setItem(name, value);
      }
    } else {
      inMemoryStorage[name] = String(value); // not everyone uses TypeScript
    }
  };

  const length = () => {
    if (isSupported()) {
      return getStorage().length;
    } else {
      return Object.keys(inMemoryStorage).length;
    }
  };

  return {
    isSupported,
    getItem,
    setItem,
    removeItem,
    clear,
    key,
    get length() {
      return length();
    },
  };
};
