import { SetStateAction, useCallback, useEffect, useState } from 'react';

import { safeLocalStorage } from '../storage';

//data version Int>1
const version = 7;
const clearedPreviousVersions: Record<string, boolean> = {};

export const getKey = (key: string, ver = version) => `${key}_v${ver}`;

const clearPreviousVersions = (key: string) => {
  if (clearedPreviousVersions[key]) return;
  clearedPreviousVersions[key] = true;

  [...Array(version - 1).keys()]
    .map((ver) => getKey(key, ver + 1))
    .forEach((realKey) => {
      safeLocalStorage.removeItem(realKey);
    });
};

export type LocalStorageOptions =
  | {
      lazy?: true;
      skip?: false;
    }
  | {
      lazy?: false;
      skip?: boolean;
    };

/** simple storage for storing STRING values. See also typed alternatives 'useObjectLocalStorage' or 'useNumberLocalStorage' */
export const useLocalStorage = (
  key: string,
  initialValue: string = null,
  options: LocalStorageOptions = {},
) => {
  const realKey = getKey(key);

  const [value, setValue] = useState({
    value: options.lazy
      ? null
      : options.skip
      ? initialValue
      : safeLocalStorage.getItem(realKey) || initialValue,
    loaded: options.lazy || options.skip ? false : true,
  });

  const setItem = (newValue: SetStateAction<string>) => {
    if (typeof newValue === 'function') {
      setValue((prev) => {
        const value = newValue(prev.value);
        safeLocalStorage.setItem(realKey, value);
        return { value, loaded: true };
      });
    } else {
      setValue({ value: newValue, loaded: true });
      safeLocalStorage.setItem(realKey, newValue);
    }
  };

  useEffect(() => {
    const newValue = options.skip
      ? initialValue
      : safeLocalStorage.getItem(realKey) || initialValue;
    if (value.value !== newValue || !value.loaded) {
      setValue({ value: newValue, loaded: true });
    }
  }, [options.skip]);

  const handleStorage = useCallback(
    (event: StorageEvent) => {
      if (event.key === realKey && event.newValue !== value.value) {
        setValue({ value: event.newValue || initialValue, loaded: true });
      }
    },
    [value],
  );

  useEffect(() => {
    if (!safeLocalStorage.isSupported) return;
    clearPreviousVersions(key);
    window.addEventListener('storage', handleStorage);
    return () => window.removeEventListener('storage', handleStorage);
  }, [handleStorage, key]);

  return [value.value, setItem, value.loaded] as const;
};
