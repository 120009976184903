import { SetStateAction, useCallback, useMemo } from 'react';

import { LocalStorageOptions, useLocalStorage } from './useLocalStorage';

/** not public, use e.g. useObjectLocalStorage */
const useTypedLocalStorage = function <T = string>(
  key: string,
  initialValue: T,
  toString: (value: T) => string,
  fromString: (value: string) => T,
  options?: LocalStorageOptions,
) {
  const [strValue, setStrValue, loaded] = useLocalStorage(
    key,
    toString(initialValue),
    options,
  );
  const value = useMemo(() => fromString(strValue), [strValue, fromString]);
  const setValue = useCallback((value: SetStateAction<T>) => {
    if (typeof value === 'function') {
      //@ts-ignore
      setStrValue((prev) => toString(value(fromString(prev))));
    } else setStrValue(toString(value));
  }, []);
  return [value, setValue, loaded] as const;
};

const numberConvert = {
  toString: (value: number) => value.toString(),
  fromString: (s: string) => Number.parseInt(s),
};

export const useNumberLocalStorage = (
  key: string,
  initialValue: number,
  options?: LocalStorageOptions,
) =>
  useTypedLocalStorage<number>(
    key,
    initialValue,
    numberConvert.toString,
    numberConvert.fromString,
    options,
  );

const jsonConvert = {
  toString: (value) => (value ? JSON.stringify(value) : null),
  fromString: JSON.parse,
};

export const useObjectLocalStorage = function <
  T extends Record<string, unknown>
>(key: string, initialValue: T = null, options?: LocalStorageOptions) {
  return useTypedLocalStorage<T>(
    key,
    initialValue,
    jsonConvert.toString,
    jsonConvert.fromString,
    options,
  );
};
