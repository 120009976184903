import { createGlobalStyle } from 'styled-components';

import { breakpoints, colors, misc, spacing, typography } from '../theme';

export const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'PlutoSansDPD';
    src: url('/fonts/PlutoSansDPDRegular.otf');
    font-weight: normal;
  }

  @font-face {
    font-family: 'PlutoSansDPD';
    src: url('/fonts/PlutoSansDPDLight.otf');
    font-weight: 300;
  }

  @font-face {
    font-family: 'PlutoSansDPD';
    src: url('/fonts/PlutoSansDPDExtraLight.otf');
    font-weight: 200;
  }

  @font-face {
    font-family: 'PlutoSansDPD';
    src: url('/fonts/PlutoSansDPDThin.otf');
    font-weight: 100;
  }

  html {
    box-sizing: border-box;
    min-height: 100vh;
  }

  *, *:before, *:after {
    box-sizing: border-box;
  }

  body {
    font-family: ${typography.fontFamily.default};
    font-feature-settings: "liga", "kern";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: ${typography.lineHeight.body}; 
    font-size: ${typography.fontSize.body}; 
    color: ${colors.black};
    background-color: ${colors.white};
    
    @media (min-width: ${breakpoints.minDesktop}) {
      background: linear-gradient(#ebeff2 75%, white);
    }
    min-height: 100vh;
  }

  a {
    color: ${colors.red_main};
    cursor: pointer;
    text-decoration: none;
    font-weight: ${typography.fontWeight.normal};
  }
  
  p {
    margin: 0 0 ${spacing.m} 0;
  }

  #__next {
    min-height: 100vh;
  }
  
  input, textarea, button, select, label, a, td {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
  }
      
  .grecaptcha-badge { 
    visibility: hidden;
  }

  // google places autocomplete dropdown styles
  .pac-container {
    font-family: ${typography.fontFamily.default};
    font-feature-settings: "liga", "kern";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: ${typography.fontSize.body}; 
    line-height: ${typography.lineHeight.body}; 
    color: ${colors.black};
    background-color: ${colors.white};
    box-shadow: ${misc.shadow_form};
  }

  .pac-item {
    cursor: pointer;
    padding: 4px 8px;

    :hover {
      background-color: ${colors.input};
    }
  }

  .pac-icon {
    display: none;
  }

  .pac-logo:after {
    /* display: none; */
  }
`;
