export enum Language {
  CS = 'cs',
  EN = 'en',
}

export enum Environment {
  LOCAL = 'local',
  DEV = 'dev',
  UAT = 'uat',
  PRODUCTION = 'production',
}

export type ConfigType = {
  app: {
    defaultLanguage: Language;
    defaultCountry: string;
    defaultGoogleApiCountry: string;
    environment: Environment;
    googleSiteId: string;
    googleApiKey: string;
    googleTagManagerId: string;
    parcelShopSearchApiUrl: string;
    parcelShopSearchUrl: string;
    parcelShopSearchUrlEn: string;
    onlineCodDpd: string;
    onlineCodDpdEn: string;
    dateFnsLocale: any;
    accountOrderListSize: number;
    accountOrderListBatchSize: number;
    accountAddressListSize: number;
    accountParcelShopsListSize: number;
  };
  api: {
    apiEndpoint: string;
    graphqlLocalEndpoint: string;
    internalServerBase: string;
  };
  contactInfo: {
    email: string;
    phone: string;
  };
  customerSupport: {
    phone: string;
    phonePrefix: string;
  };
  emergency: {
    phone: string;
  };
};
