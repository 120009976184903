/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Supported formats: [yyyy-MM-dd'T'HH:mm:ss+ZZ:ZZ] */
  ZonedDateTime: any;
  /** Unrepresentable type */
  UNREPRESENTABLE: any;
  /** Long type */
  Long: any;
};

export type PiaAndPlaceDetails = {
  pia: PiaDetails;
  place: PlaceDetails;
};

/** Query root */
export type Query = {
  /** Returns PIA details including location info, BACKEND_CONNECTION_FAILED if connection to backend fails. */
  pia?: Maybe<PiaAndPlaceDetails>;
  /** Returns payment detail. */
  payment?: Maybe<PayURequest>;
};


/** Query root */
export type QueryPiaArgs = {
  id: Scalars['String'];
};


/** Query root */
export type QueryPaymentArgs = {
  id: Scalars['String'];
};

export type Address = {
  city: Scalars['String'];
  country: Scalars['String'];
  department?: Maybe<Scalars['String']>;
  floorSpecification?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  street: Scalars['String'];
  zip: Scalars['String'];
};

export type OpeningHours = {
  friday?: Maybe<Array<Maybe<Interval>>>;
  monday?: Maybe<Array<Maybe<Interval>>>;
  saturday?: Maybe<Array<Maybe<Interval>>>;
  sunday?: Maybe<Array<Maybe<Interval>>>;
  thursday?: Maybe<Array<Maybe<Interval>>>;
  tuesday?: Maybe<Array<Maybe<Interval>>>;
  wednesday?: Maybe<Array<Maybe<Interval>>>;
};

export type PiaDetails = {
  amount: Scalars['Float'];
  currency: Scalars['String'];
  delivery: Delivery;
  orderId: Scalars['Long'];
  payer?: Maybe<Recipient>;
  sender: Sender;
  status: PiaStatus;
};


export enum PiaStatus {
  DELIVERED = 'DELIVERED',
  ERROR = 'ERROR',
  EXPIRED = 'EXPIRED',
  PAID = 'PAID',
  READY_TO_PICKUP = 'READY_TO_PICKUP',
  TO_BE_PAID = 'TO_BE_PAID'
}

export type ContactInfo = {
  email: Scalars['String'];
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
  web: Scalars['String'];
};

export type Features = {
  expressAllowed: Scalars['Boolean'];
  openInLateAfternoon: Scalars['Boolean'];
  openOnWeekends: Scalars['Boolean'];
  pickupAllowed: Scalars['Boolean'];
  returnAllowed: Scalars['Boolean'];
};

export type PayUCreateOrderResponse = {
  extOrderId: Scalars['String'];
  orderId: Scalars['String'];
  redirectUri: Scalars['String'];
  status: Status;
};

export type Status = {
  statusCode: Scalars['String'];
  statusDesc?: Maybe<Scalars['String']>;
};

export type Coordinates = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type PlaceDetails = {
  contactInfo: ContactInfo;
  dropOffAllowed: Scalars['Boolean'];
  enabled: Scalars['Boolean'];
  features: Features;
  id: Scalars['String'];
  location: Location;
  lockerProvider?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  openingHours: OpeningHours;
  paymentOptions: PaymentOptions;
  photos: Array<Maybe<Scalars['String']>>;
  type: Scalars['String'];
};

export type Recipient = {
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export enum PayUStatus {
  CANCELED = 'CANCELED',
  COMPLETED = 'COMPLETED',
  CREATED = 'CREATED',
  PENDING = 'PENDING',
  REFUNDED = 'REFUNDED',
  WAITING_FOR_CONFIRMATION = 'WAITING_FOR_CONFIRMATION'
}

export type PaymentOptions = {
  cardPaymentAllowed: Scalars['Boolean'];
  codAllowed: Scalars['Boolean'];
  onlineCodAllowed: Scalars['Boolean'];
  prepaidAllowed: Scalars['Boolean'];
};

export type PayURequest = {
  orderId: Scalars['String'];
  paymentId: Scalars['String'];
  redirectUri: Scalars['String'];
  status: PayUStatus;
};

export type Sender = {
  name: Scalars['String'];
};



export type Delivery = {
  expirationDate?: Maybe<Scalars['ZonedDateTime']>;
  pudoId?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

/** Mutation root */
export type Mutation = {
  /** Creates order in PayU to allow customer to make payment. Returns WRONG_STATE if called on order not in TO_BE_PAID state, NOT_FOUND when pia does not exist. */
  createPayment?: Maybe<PayUCreateOrderResponse>;
  /** Returns true if call to BE was successful, BACKEND_CONNECTION_FAILED if connection to backend fails. */
  resendSMS: Scalars['Boolean'];
};


/** Mutation root */
export type MutationCreatePaymentArgs = {
  redirectUrl: Scalars['String'];
  id: Scalars['String'];
};


/** Mutation root */
export type MutationResendSmsArgs = {
  id: Scalars['String'];
};

export type Location = {
  address: Address;
  coordinates: Coordinates;
  note?: Maybe<Scalars['String']>;
};

export type Interval = {
  close: Scalars['String'];
  open: Scalars['String'];
};

export type CreatePaymentMutationVariables = Exact<{
  redirectUrl: Scalars['String'];
  id: Scalars['String'];
}>;


export type CreatePaymentMutation = { createPayment?: Maybe<{ extOrderId: string, orderId: string, redirectUri: string, status: { statusCode: string } }> };

export type PaymentQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type PaymentQuery = { payment?: Maybe<{ orderId: string, paymentId: string, redirectUri: string, status: PayUStatus }> };

export type PiaQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type PiaQuery = { pia?: Maybe<{ pia: { amount: number, currency: string, orderId: any, status: PiaStatus, delivery: { expirationDate?: Maybe<any>, pudoId?: Maybe<string>, type: string }, payer?: Maybe<{ email?: Maybe<string>, name?: Maybe<string>, phone?: Maybe<string> }>, sender: { name: string } }, place: { dropOffAllowed: boolean, enabled: boolean, id: string, lockerProvider?: Maybe<string>, note?: Maybe<string>, photos: Array<Maybe<string>>, type: string, contactInfo: { email: string, name: string, note?: Maybe<string>, phone: string, web: string }, features: { expressAllowed: boolean, openInLateAfternoon: boolean, openOnWeekends: boolean, pickupAllowed: boolean, returnAllowed: boolean }, location: { note?: Maybe<string>, address: { city: string, country: string, department?: Maybe<string>, floorSpecification?: Maybe<string>, note?: Maybe<string>, street: string, zip: string }, coordinates: { latitude: number, longitude: number } }, openingHours: { monday?: Maybe<Array<Maybe<{ open: string, close: string }>>>, tuesday?: Maybe<Array<Maybe<{ open: string, close: string }>>>, wednesday?: Maybe<Array<Maybe<{ open: string, close: string }>>>, thursday?: Maybe<Array<Maybe<{ open: string, close: string }>>>, friday?: Maybe<Array<Maybe<{ open: string, close: string }>>>, saturday?: Maybe<Array<Maybe<{ open: string, close: string }>>>, sunday?: Maybe<Array<Maybe<{ open: string, close: string }>>> }, paymentOptions: { cardPaymentAllowed: boolean, codAllowed: boolean, onlineCodAllowed: boolean, prepaidAllowed: boolean } } }> };

export type ResendSmsMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type ResendSmsMutation = { resendSMS: boolean };
